<template>
  <div class="bg-white h-100 custom-overflow rounded-lg pa-4">
    <!-- datatable details begin -->
    <b-row class="mb-5">
      <b-col cols="10">
        <datatable-detail
          :table_properties="table_properties"
          grid="col-12"
        ></datatable-detail>
      </b-col>
    </b-row>
    <!-- datatable details end -->
    <!-- main content begins -->
    <b-row class="mx-2">
      <b-col cols="12" md="4" lg="3">
        <v-card class="min-h-360px" elevation="4">
          <v-card-title class="d-flex justify-content-center">
            <div>
              <b-avatar
                badge-variant="primary"
                v-if="currentUser.data.photo.url"
                :alt="currentUser.data.name"
                :src="currentUser.data.photo.url"
                size="8rem"
              >
              </b-avatar>
              <b-avatar
                v-else
                size="8rem"
                variant="success"
                :text="currentUser.data.name.slice(0, 1)"
              ></b-avatar>

              <div class="profile__edit_avatar">
                <div class="position-relative edit-avatar">
                  <v-file-input
                    class="current-photo-upload"
                    v-model="current_photo"
                    accept="image/png, image/jpeg, image/bmp"
                    dense
                    hide-details
                    @change="uploadPicture"
                  ></v-file-input>
                  <b-avatar
                    class="edit-avatar-icon"
                    variant="primary"
                    icon="pencil"
                  ></b-avatar>
                </div>
              </div>
            </div>
          </v-card-title>
          <div class="d-flex justify-content-center">
            <div class="text-center">
              <h1>{{ currentUser.data.name }}</h1>
              <p class="mb-0 text--secondary">{{ currentUser.data.email }}</p>
              <p class="text--secondary font-weight-bolder">
                {{ organization }}
              </p>
            </div>
          </div>
          <hr class="mx-4" />
          <v-tabs class="pb-5" vertical v-model="profileTabs">
            <v-tab>
              <span class="svg-icon">
                <v-icon left size="18" color="#000000de"
                  >mdi-account-outline</v-icon
                >
              </span>
              <div class="font-weight-normal font-size-lg ml-3">
                Personal Information
              </div>
            </v-tab>
            <v-tab>
              <span class="svg-icon">
                <v-icon left size="18" color="#000000de"
                  >mdi-key-outline</v-icon
                >
              </span>
              <div class="font-weight-normal font-size-lg ml-3">
                Change Password
              </div>
            </v-tab>
          </v-tabs>
        </v-card>
      </b-col>
      <!-- Form fields start -->
      <b-col cols="12" md="8" lg="8" xl="7">
        <v-card class="min-h-360px" elevation="4">
          <v-tabs-items v-model="profileTabs">
            <v-tab-item>
              <KTPersonalInformation />
            </v-tab-item>
            <v-tab-item>
              <KTChangePassword />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </b-col>
      <!-- Form fields end -->
    </b-row>
    <!-- main content end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetailNew";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation2.vue";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword2";

export default {
  name: "Profile2",
  components: { DatatableDetail, KTPersonalInformation, KTChangePassword },
  data: () => ({
    table_properties: {
      title: "Profile",
      description: "Change account setting & configurations",
    },
    profileTabs: null,
    current_photo: null,
  }),
  methods: {
    uploadPicture(e) {
      const data = new FormData();
      data.append("name", this.currentUser.data.name);
      data.append("email", this.currentUser.data.email);
      data.append("photo", e);
      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, data).then(() => {
          if (this.isNeedToLogout) {
            this.$store.commit(PURGE_AUTH);
          }
        });
      }, 1);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    organization: function () {
      return this.$store.getters.currentUser.data.organization_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-overflow {
  overflow-y: scroll;
  position: relative;
}
</style>

<style lang="scss">
@media (min-width: 960px) {
  .min-h-360px {
    min-height: 370px;
  }
}
.v-tab--active {
  .v-icon {
    color: #7e0453 !important;
    caret-color: #7e0453 !important;
  }
}
.current-photo-upload {
  .v-input__control {
    display: none;
  }
}
.profile__edit_avatar {
  top: -32px;
  position: relative;
  right: -61px;
}
.edit-avatar {
  position: relative;
  .current-photo-upload {
    position: absolute;
    z-index: 10;
    opacity: 0;
  }
  .edit-avatar-icon {
    position: absolute;
    background-color: #8950fcff !important;
  }
}
</style>
