<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Form-->
    <h2 class="ml-5 mt-4">Personal Information</h2>
    <hr />
    <form class="form">
      <!--begin::Body-->
      <div class="row">
        <div class="col col-md-10">
          <div class="card-body">
            <div class="d-md-flex mb-5">
              <label
                for="name"
                class="font-weight-bolder font-size-lg mr-md-10 mt-3"
                >Name</label
              >
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid border-gray-400 rounded-lg bg-light-o-60"
                type="text"
                v-bind:value="currentUser.data.name"
              />
            </div>
            <div class="d-md-flex mb-3">
              <label
                for="email"
                class="font-weight-bolder font-size-lg mr-md-10 mt-3"
                >Email</label
              >
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid border-gray-400 rounded-lg bg-light-o-60"
                placeholder="Email"
                v-bind:value="currentUser.data.email"
              />
            </div>
            <div class="d-flex justify-content-end mt-10">
              <b-button
                pill
                class="mt-3 iq-btn"
                @click="save()"
                ref="kt_save_changes"
                >Save changes</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "/media/users/blank.png",
      current_photo: null,
      isNeedToLogout: false,
      formData: {
        name: null,
        email: null,
      },
    };
  },
  mounted() {
    // this.current_photo = this.currentUser.data.photo.url;
    // console.log(this.currentUser);
  },
  methods: {
    validateInfo() {
      var data = new FormData();
      let isValid = true;
      let message = "";

      var name = this.$refs.name.value;
      var email = this.$refs.email.value;
      var photo = this.current_photo;
      if (name) {
        data.append("name", name);
      } else {
        message += " User name required! ";
        isValid = false;
      }
      if (email) {
        data.append("email", email);
        if (email !== this.currentUser.data.email) {
          this.isNeedToLogout = true;
        }
      } else {
        message += " User email required! ";
        isValid = false;
      }
      if (photo) {
        data.append("photo", photo);
      }

      if (isValid === false) {
        Swal.fire({
          title: "Invalid data",
          text: message,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      return data;
    },
    save() {
      // set spinner to submit button
      const data = this.validateInfo();
      if (data) {
        const submitButton = this.$refs["kt_save_changes"];
        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch(UPDATE_PERSONAL_INFO, data).then(() => {
            if (this.isNeedToLogout) {
              this.$store.commit(PURGE_AUTH);
            }
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 1);
      }
    },
    cancel() {
      this.$refs.name.value = this.currentUser.data.name;
      // this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      // this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      // this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUser.data.email;
      // this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = null;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      // console.log("file ", file);
      this.current_photo = file;
      // if (typeof FileReader === "function") {
      //   const reader = new FileReader();

      //   reader.onload = (event) => {
      //     this.current_photo = event.target.result;
      //   };

      //   reader.readAsDataURL(file);
      // } else {
      //   alert("Sorry, FileReader API not supported");
      // }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>
